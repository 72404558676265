// const OSS = require('ali-oss')
import OSS from "ali-oss";

export default function client(ary) {
  return new OSS({
    // yourRegion填写Bucket所在地域。以华东1（杭州）为例，Region填写为oss-cn-hangzhou。
    region: "oss-cn-shanghai",
    // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
    accessKeyId: ary.AccessKeyId,
    accessKeySecret: ary.AccessKeySecret,
    // 从STS服务获取的安全令牌（SecurityToken）。
    stsToken: ary.SecurityToken,
    // v4签名算法
    authorizationV4: true,
    // 填写Bucket名称。
    bucket: "finder-cloud",
  });
}
