import request from '@/utils/request'

// 查询素材列表
export function listMaterial(query) {
  return request({
    url: '/system/sysMaterial/list',
    method: 'get',
    params: query
  })
}

// 查询字典
export function dict() {
  return request({
    url: '/system/dict/data/list?dictType=sys_material_type&pageSize=50',
    method: 'get'
  })
}

// 查询字典
export function dictstatus() {
  return request({
    url: '/system/dict/data/list?dictType=sys_material_status&pageSize=50',
    method: 'get'
  })
}

// 查询素材详细
export function getMaterial(materialId) {
  return request({
    url: '/system/material/' + materialId,
    method: 'get'
  })
}

// 新增素材
export function addMaterial(data) {
  return request({
    url: '/system/material',
    method: 'post',
    data: data
  })
}

// 修改素材
export function updateMaterial(data) {
  return request({
    url: '/system/material',
    method: 'put',
    data: data
  })
}

// 删除素材
export function delMaterial(materialId) {
  return request({
    url: '/system/sysMaterial/' + materialId,
    method: 'delete'
  })
}

// 获取STS上传令牌
export function getSTSToken() {
  return request({
    url: '/system/sysMaterial/getOSSToken',
    method: 'get'
  })
}

export function AddFn(data) {
  return request({
    url: '/system/sysMaterial/saveOrUpdate',
    method: 'post',
    async: true,
    data: data
  })
}

export function UpdateFn(data) {
  return request({
    url: '/system/sysMaterial/saveOrUpdate',
    method: 'put',
    async: true,
    data: data
  })
}

export function optionFn(data) {
  return request({
    url: '/OptionValue/list',
    method: 'get',
    async: true,
    params: data
  })
}

export function uploadFn(data) {
  return request({
    url: '/system/sysMaterial/uploadFile',
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}