import { createI18n } from 'vue-i18n'
import en from './locales/en.json'
import zh from './locales/zh.json'

const messages = {
  en: en,
  zh: zh
}

const i18n = createI18n({
  legacy: false, // 如果你使用的是 Vue 3 的 Composition API，将此设置为 false
  locale: 'zh', // 设置默认语言
  messages
})

export default i18n