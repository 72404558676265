import usePermissionStore from "./permission";
import { getNormalPath } from "@/utils/ruoyi";
const usePortalStore = defineStore("portal", {
  state: () => ({
    applicationList: [], // 应用列表
    focusRoute: "", // 点击应用跳转的路由
    focusMenu: "", // 点击应用跳转的菜单
    focusMenuList: [], // 点击应用跳转的菜单列表
    focusMenuBasePath: "", // 点击应用跳转的菜单基础路径
  }),
  getters: {
    recentApplicationList: (state) => {
      return JSON.parse(localStorage.getItem("recentApplicationList")) || [];
    },
  },
  actions: {
    setApplicationList(data) {
      this.applicationList = data;
    },
    addRecentList(data) {
      const { appId, appName } = data;
      // 添加最近使用应用
      let _recentApplicationList =
        JSON.parse(localStorage.getItem("recentApplicationList")) || [];
      let _recentApplicationIdList =
        JSON.parse(localStorage.getItem("recentApplicationIdList")) || [];
      // 判断当前应用是否打开过，没有打开过的话计入最近使用
      if (_recentApplicationIdList.indexOf(appId) === -1) {
        _recentApplicationList.unshift(data);
        _recentApplicationIdList.unshift(appId);
        localStorage.setItem(
          "recentApplicationList",
          JSON.stringify(_recentApplicationList)
        );
        localStorage.setItem(
          "recentApplicationIdList",
          JSON.stringify(_recentApplicationIdList)
        );
      }

      // 获取跳转对应的url
      const permissionStore = usePermissionStore();
      // 获取当前应用在routerTree中的完整path
      const findCurrentRoute = (routes, targetPath, parentPath = "") => {
        for (const route of routes) {
          const currentPath =
            parentPath + (route.path.startsWith("/") ? "" : "/") + route.path;
          if (route.path === targetPath) {
            return {
              route,
              fullPath: currentPath,
            };
          }
          if (route.children) {
            const found = findCurrentRoute(
              route.children,
              targetPath,
              currentPath
            );
            if (found) return found;
          }
        }
        return null;
      };
      console.log("--data--", permissionStore.sidebarRouters);
      // 如果当前应用目录有子目录，则展示将所有层级的子目录的path前拼接上当前目录的完整path
      const currentRoute = findCurrentRoute(
        permissionStore.sidebarRouters,
        data.path
      );
      console.log("--currentRoute--", currentRoute);

      if (currentRoute) {
        const { route, fullPath } = currentRoute;
        this.focusMenuBasePath = fullPath;

        // 递归处理所有层级的子节点
        const processChildren = (children, parentPath) => {
          if (!children) return [];
          return children.map((child) => {
            const currentPath = getNormalPath(parentPath + "/" + child.path);
            return {
              ...child,
              path: currentPath,
              children: processChildren(child.children, currentPath),
            };
          });
        };
        console.log("--currentRoute--", currentRoute);
        if (route.children) {
          this.focusMenuList = processChildren(route.children, fullPath);
          this.focusMenu = [route];
          // 递归查找第一个最子节点
          const findFirstLeafNode = (nodes) => {
            if (!nodes || nodes.length === 0) return null;
            const firstNode = nodes[0];
            if (!firstNode.children || firstNode.children.length === 0) {
              return firstNode;
            }
            return findFirstLeafNode(firstNode.children);
          };

          // 获取第一个叶子节点并设置完整路径
          const firstLeaf = findFirstLeafNode(this.focusMenuList);
          if (firstLeaf) {
            this.focusRoute = firstLeaf.path;
          }
        } else {
          this.focusRoute = fullPath;
          this.focusMenu = [route];
          this.focusMenuList = [route];
        }
      }
    },
  },
});

export default usePortalStore;
