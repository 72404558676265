<template>
  <div v-if="!item.hidden">
    <template v-if="
      hasOneShowingChild(item.children, item) &&
      (!onlyOneChild.children || onlyOneChild.noShowingChildren) &&
      !item.alwaysShow">
      <app-link v-if="onlyOneChild.meta" :to="resolvePath(onlyOneChild.path, onlyOneChild.query)">
        <el-menu-item :index="resolvePath(onlyOneChild.path)" :class="{ 'sub-menu-title-noDropdown': !isNest }">
          <span style="display: flex; align-items: center; justify-content: center">
            <svg-icon v-if="thisLevel <= 2" class="shouye" :icon-class="onlyOneChild.meta.icon || item.meta.icon
              // getIcon(
              //   onlyOneChild.meta.icon || (item.meta && item.meta.icon),
              //   onlyOneChild.meta.icon ? onlyOneChild.name : item.name,
              //   onlyOneChild.meta.icon ? onlyOneChild : item
              // )
              " />
          </span>

          <span v-if="thisLevel === 1" class="menu-title noDropDownTitle">
            {{ onlyOneChild.meta.title }}
          </span>
          <template #title>
            <span class="menu-title" :class="thisLevel > 2 ? 'marginLeft-13' : ''"
              :title="hasTitle(onlyOneChild.meta.title)">
              {{ onlyOneChild.meta.title }}
            </span>
          </template>
        </el-menu-item>
      </app-link>
    </template>

    <el-sub-menu v-else ref="subMenu" :index="resolvePath(item.path)" popper-append-to-body>
      <template v-if="item.meta" #title>
        <span style="display: flex; align-items: center; justify-content: center">
          <svg-icon v-if="thisLevel <= 2" :icon-class="item.meta.icon" />
        </span>
        <span class="menu-title" :class="thisLevel > 2 ? 'marginLeft-13' : ''" :title="hasTitle(item.meta.title)">{{
          item.meta.title }}</span>
      </template>

      <sidebar-item v-for="child in item.children" :key="child.path" :is-nest="true" :item="child"
        :base-path="resolvePath(child.path)" :level="thisLevel" class="nest-menu" />
    </el-sub-menu>
  </div>
</template>

<script setup>
import { isExternal } from "@/utils/validate";
import AppLink from "./Link";
import { getNormalPath } from "@/utils/ruoyi";
import usePortalStore from "@/store/modules/portal";

const jumpFromPortal = computed(() => {
  return localStorage.getItem('focusMenu')
})
const route = useRoute();
const portalStore = usePortalStore();
const activeMenu = computed(() => {
  const { meta, path } = route;
  return path;
});
const props = defineProps({
  // route object
  item: {
    type: Object,
    required: true,
  },
  isNest: {
    type: Boolean,
    default: false,
  },
  basePath: {
    type: String,
    default: "",
  },
  level: {
    // 目录层级，用于判断，超过两层目录不显示图标
    type: Number,
    default: "",
  },
});

const onlyOneChild = ref({});
const thisLevel = props.level + 1; // 当前目录层级
function isActive(path) {
  if (window.location.href.indexOf(path) > -1) {
    return true;
  } else {
    return false;
  }
}

function getIcon(iconName, name, data) {
  const lowerName = name.charAt(0).toLowerCase() + name.slice(1);
  if (activeMenu.value.indexOf(lowerName) > -1) {
    console.log(activeMenu.value + "---" + name);
    console.log(data);
    return iconName + "-click";
  } else {
    return iconName;
  }
}

function hasOneShowingChild(children = [], parent) {
  if (!children) {
    children = [];
  }
  const showingChildren = children.filter((item) => {
    if (item.hidden) {
      return false;
    } else {
      // Temp set(will be used if only has one showing child)
      onlyOneChild.value = item;
      return true;
    }
  });
  console.log('jumpFromPortal', showingChildren)

  // When there is only one child router, the child router is displayed by default
  if (showingChildren.length === 1) {
    return true;
  }
  // Show parent if there are no child router to display
  if (showingChildren.length === 0) {
    onlyOneChild.value = {...parent, path: "", noShowingChildren: true};
    return true;
  }

  return false;
}

// 判断字符串是否为JSON字符串
function isJsonString(str) {
  try {
    JSON.parse(str)
    return true;
  } catch (e) {
    console.log('This menu query is not a json str')
    return false;
  }
}

function resolvePath(routePath, routeQuery) {
  if (isExternal(routePath)) {
    return routePath;
  }
  if (isExternal(props.basePath)) {
    return props.basePath;
  }
  console.log('basePath', props.basePath)
  // 这里可能会因为用户的误操作，导致配置了不合法的参数，JSON.parse报错，导致页面无法正常渲染，添加异常处理
  if (routeQuery) {
    let query = isJsonString(routeQuery) ? JSON.parse(routeQuery) : '';
    return {
      path: getNormalPath(props.basePath + "/" + routePath),
      query: query,
    };
  }
  return getNormalPath(props.basePath + "/" + routePath);
}

function hasTitle(title) {
  if (title.length > 5) {
    return title;
  } else {
    return "";
  }
}
</script>
<style lang="scss" scoped>
.svg-icon {
  font-size: 24px;
  margin-right: 0;
  color: #ffffff;
}

.marginLeft-13 {
  margin-left: 20px;
}
</style>
