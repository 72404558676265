<template>
    <div class="recent-application">
        <el-dropdown trigger="click">
            <span class="el-dropdown-link">
                <el-button>
                    {{ title }}
                </el-button>
                <el-icon class="el-icon--right"><arrow-down /></el-icon>
            </span>

            <template #dropdown>
                <el-dropdown-menu class="recent-menu">
                    <!-- 标签页 -->
                    <div class="tabs-header">
                        <div :class="['tab-item', activeTab === 'recent' ? 'active' : '']"
                            @click="activeTab = 'recent'">
                            最近使用
                        </div>
                        <div :class="['tab-item', activeTab === 'my' ? 'active' : '']" @click="activeTab = 'my'">
                            我的应用
                        </div>
                    </div>

                    <!-- 搜索框 -->
                    <div class="search-box">
                        <el-input v-model="searchText" placeholder="请输入功能名称" prefix-icon="Search" clearable />
                    </div>

                    <!-- 应用列表 -->
                    <div class="app-list">
                        <el-dropdown-item v-for="app in filteredApps" :key="app.appId" :command="app.path"
                            @click="handleCommand(app)">
                            <div class="app-item">
                                <el-avatar :size="32" :src="app.icon" class="app-icon">
                                    <ApplicationIcon :icon="app.icon" :bgColor="app.bgColor" />
                                </el-avatar>
                                <span class="app-name">{{ app.appName }}</span>
                            </div>
                        </el-dropdown-item>
                    </div>
                </el-dropdown-menu>
            </template>
        </el-dropdown>
    </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { ArrowDown, Grid } from '@element-plus/icons-vue'
import { useRouter } from 'vue-router'
import usePortalStore from '@/store/modules/portal'
import ApplicationIcon from '@/components/ApplicationIcon/index.vue'
const portalStore = usePortalStore()
const router = useRouter()
const activeTab = ref('recent')
const searchText = ref('')
const test = ref('')

const title = computed(() => {
    const focusMenu = localStorage.getItem('focusMenu') ? JSON.parse(localStorage.getItem('focusMenu')) : null
    return focusMenu ? focusMenu.appName : '钒锝科技管理系统VUE3'
})
// 根据标签页和搜索过滤应用
const filteredApps = computed(() => {
    const apps = activeTab.value === 'recent' ? portalStore.recentApplicationList : portalStore.applicationList
    if (!searchText.value) return apps
    return apps.filter(app =>
        app.appName.includes(searchText.value.toLowerCase())
    )
})

// 处理应用点击
const handleCommand = (command) => {
    console.log('************', command)
    const { appId, path, model } = command
    localStorage.setItem('focusMenu', JSON.stringify(command))
    portalStore.addRecentList(command)
    router.push(portalStore.focusRoute)
}
onMounted(() => {
    console.log('************', filteredApps.value)
})
</script>

<style lang="scss" scoped>
.recent-application {
    flex: none;
    width: 140px;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
}

.tabs-header {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    border-bottom: 1px solid #eee;

    .tab-item {
        flex: 1;
        text-align: center;
        padding: 12px 0;
        cursor: pointer;
        color: #666;

        &.active {
            color: #409EFF;
            font-weight: bold;
        }
    }
}

.search-box {
    padding: 12px;

    :deep(.el-input__wrapper) {
        border: 1px solid #dcdfe6;
        box-shadow: none;

        &:hover {
            border-color: #c0c4cc;
        }

        &.is-focus {
            border-color: #409EFF;
        }
    }
}

.app-list {
    max-height: 300px;
    overflow-y: auto;

    .app-item {
        display: flex;
        align-items: center;
        padding: 8px 0;

        .app-icon {
            margin-right: 12px;
            flex-shrink: 0;
        }

        .app-name {
            color: #333;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 200px;
        }
    }

    :deep(.el-dropdown-menu__item) {
        &:hover .app-name {
            overflow: visible;
            white-space: normal;
            word-break: break-all;
        }
    }
}

:deep(.el-dropdown) {
    width: 100%;

    button {
        border: none;
    }
}

.el-dropdown-link {
    display: flex;
    align-items: center;
    width: 100%;

    button {
        padding: 5px;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
</style>