<template>
  <div class="component-upload-image">
    <el-upload :file-list="fileList" :headers="headers" multiple action="" :https-request="upToAliYunOss"
      list-type="picture-card" :on-success="handleUploadSuccess" :before-upload="handleBeforeUpload" :limit="limit"
      :on-error="handleUploadError" :on-exceed="handleExceed" ref="imageUpload" :before-remove="handleDelete"
      :show-file-list="true" :on-preview="handlePictureCardPreview" :class="{ hide: fileList.length >= limit }">
      <el-icon class="avatar-uploader-icon">
        <plus />
      </el-icon>
    </el-upload>
    <!-- 上传提示 -->
    <div class="el-upload__tip" v-if="showTip">
      请上传
      <template v-if="fileSize">
        大小不超过 <b style="color: #f56c6c">{{ fileSize }}MB</b>
      </template>
      <template v-if="fileType">
        格式为 <b style="color: #f56c6c">{{ fileType.join("/") }}</b>
      </template>
      的文件
    </div>

    <el-dialog :draggable="true" v-model="dialogVisible" title="预览" width="800px" append-to-body>
      <img :src="dialogImageUrl" style="display: block; max-width: 100%; margin: 0 auto" />
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, computed, getCurrentInstance, defineProps, defineEmits } from 'vue';
import { getSTSToken } from "@/api/system/material.js";
import Client from "@/utils/ossClient";
const props = defineProps({
  maxSize: {
    type: Number,
    default: 1024 * 1024 * 5, // 5MB
  },
  modelValue: [String, Object, Array],
  // 图片数量限制
  limit: {
    type: Number,
    default: 5,
  },
  // 大小限制(MB)
  fileSize: {
    type: Number,
    default: 5,
  },
  // 模块
  module: {
    type: String,
    default: "",
  },
  // 物料类型
  materialType: {
    type: String,
    default: "",
  },
  // 文件类型, 例如['png', 'jpg', 'jpeg']
  fileType: {
    type: Array,
    default: () => ["png", "jpg", "jpeg"],
  },
  // 是否显示提示
  isShowTip: {
    type: Boolean,
    default: true
  },
});

const { proxy } = getCurrentInstance();
const emits = defineEmits(["uploadSuccess"]);
const number = ref(0);
const uploadList = ref([]);
const dialogImageUrl = ref("");
const dialogVisible = ref(false);
const baseUrl = import.meta.env.VITE_APP_BASE_API;
const fileList = ref([]);
const percentage = ref(0);
const showTip = computed(
  () => props.isShowTip && (props.fileType || props.fileSize)
);

const getCookie = (cname) => {
  var name = cname + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i].trim();
    if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
  }
  return "";
};

const headers = ref({
  Authorization: "Holder " + getCookie("token"),
});

watch(() => props.modelValue, val => {
  if (val) {
    // 首先将值转为数组
    const list = Array.isArray(val) ? val : props.modelValue.split(",");
    // 然后将数组转为对象数组
    fileList.value = list.map(item => {
      if (typeof item === "string") {
        item = { name: item, url: item };
      }
      return item;
    });
  } else {
    fileList.value = [];
    return [];
  }
}, { deep: true, immediate: true });

// 阿里云上传
const upToAliYunOss = async (uploadObject) => {
  const { file, size } = uploadObject

  if (size > props.maxSize) {
    ElMessage.error('文件大小超过限制')
    fileList.value.length = 0
    return
  }

  // uploadButtonDisabled.value = true

  try {
    const res = await getSTSToken()
    if (res.data.StatusCode === '200') {
      const filePath = `finder-system/dev/material/${props.module}/${props.materialType}/finderDevelop${new Date().getTime()}${file.name}`

      const options = {
        progress: (p) => {
          percentage.value = parseInt((p * 100).toString())
        },
        parallel: 10,
        partSize: 1024 * 1024,
        meta: {},
        mime: "text/plain"
      }

      try {
        const client = Client(res.data)
        const upResponse = await client.multipartUpload(filePath, file, options)
        console.log("上传成功", upResponse)

        // 构建返回结果
        const result = {
          name: filePath,
          oldname: file.name,
          url: `http://oss-cloud.finder-cas.com/${filePath}`
        }

        // 更新文件列表
        fileList.value = [{
          name: file.name,
          url: result.url
        }]

        uploadButtonDisabled.value = false

        // 直接调用 materialSuccess，传入结果
        materialSuccess(result)

        return result

      } catch (uploadError) {
        console.error('文件上传失败:', uploadError)
        ElMessage.error('文件上传失败')
        uploadButtonDisabled.value = false
        throw uploadError
      }
    }
  } catch (err) {
    console.error('获取上传凭证失败:', err)
    ElMessage.error('获取上传凭证失败')
    uploadButtonDisabled.value = false
    throw err
  }
}


// 上传前loading加载
function handleBeforeUpload(file) {
  let isImg = false;
  if (props.fileType.length) {
    let fileExtension = "";
    if (file.name.lastIndexOf(".") > -1) {
      fileExtension = file.name.slice(file.name.lastIndexOf(".") + 1);
    }
    isImg = props.fileType.some(type => {
      if (file.type.indexOf(type) > -1) return true;
      if (fileExtension && fileExtension.indexOf(type) > -1) return true;
      return false;
    });
  } else {
    isImg = file.type.indexOf("image") > -1;
  }
  if (!isImg) {
    proxy.$modal.msgError(
      `文件格式不正确, 请上传${props.fileType.join("/")}图片格式文件!`
    );
    return false;
  }
  if (props.fileSize) {
    const isLt = file.size / 1024 / 1024 < props.fileSize;
    if (!isLt) {
      proxy.$modal.msgError(`上传头像图片大小不能超过 ${props.fileSize} MB!`);
      return false;
    }
  }
  proxy.$modal.loading("正在上传图片，请稍候...");
  number.value++;
}

// 文件个数超出
function handleExceed() {
  proxy.$modal.msgError(`上传文件数量不能超过 ${props.limit} 个!`);
}

// 上传成功回调
function handleUploadSuccess(res, file) {
  if (res.code === 200) {
    uploadList.value.push({ name: res.data.url, url: res.data.url });
    uploadedSuccessfully();
  } else {
    number.value--;
    proxy.$modal.closeLoading();
    proxy.$modal.msgError(res.msg);
    proxy.$refs.imageUpload.handleRemove(file);
    uploadedSuccessfully();
  }
}

// 删除图片
function handleDelete(file) {
  const findex = fileList.value.map(f => f.name).indexOf(file.name);
  if (findex > -1 && uploadList.value.length === number.value) {
    fileList.value.splice(findex, 1);
    emit("update:modelValue", listToString(fileList.value));
    return false;
  }
}

// 上传结束处理
function uploadedSuccessfully() {
  if (number.value > 0 && uploadList.value.length === number.value) {
    fileList.value = fileList.value.filter(f => f.url !== undefined).concat(uploadList.value);
    uploadList.value = [];
    number.value = 0;
    emit("update:modelValue", listToString(fileList.value));
    proxy.$modal.closeLoading();
  }
}
// 上传失败
function handleUploadError() {
  proxy.$modal.msgError("上传图片失败");
  proxy.$modal.closeLoading();
}

// 预览
function handlePictureCardPreview(file) {
  dialogImageUrl.value = file.url;
  dialogVisible.value = true;
}

// 对象转成指定字符串分隔
function listToString(list, separator) {
  let strs = "";
  separator = separator || ",";
  for (let i in list) {
    if (undefined !== list[i].url && list[i].url.indexOf("blob:") !== 0) {
      strs += list[i].url.replace(baseUrl, "") + separator;
    }
  }
  return strs != "" ? strs.substr(0, strs.length - 1) : "";
}
</script>

<style scoped lang="scss">
// .el-upload--picture-card 控制加号部分
:deep(.hide .el-upload--picture-card) {
  display: none;
}
</style>